import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layouts/layout"
import * as myModule from "../components/dynamic"
import Seo from "../components/Seo/seo"
function FindALoanPage({ data }) {
  const wpData = data.wpPage.sections.blocks
  const seo = data?.wpPage?.seo
        console.log(wpData,'data')

  return (
    <Layout>
      <Seo
        title={seo.title}
        description={seo.metaDesc}
        featuredImage={
          seo.opengraphImage?.localFile.childImageSharp.gatsbyImageData
        }
      />
      {wpData.map(row => {
        // assign component math with name
        const Component = myModule[row.fieldGroupName.split("_").slice(-1)[0]]
        return <Component key={row.fieldGroupName} {...row} />
      })}
    </Layout>
  )
}

export default FindALoanPage

export const query = graphql`
  query {
    wpPage(id: { eq: "cG9zdDozMDU=" }) {
      seo {
        ...SeoData
      }
      sections {
        blocks {
          ... on WpPage_Sections_Blocks_PageHeader {
            fieldGroupName
            title
            subtitle
            box {
              title
              link {
                title
                url
                target
              }
            }
          }
          ... on WpPage_Sections_Blocks_Cta1 {
            fieldGroupName
            title
            content
            buttons {
              link {
                url
                title
                target
              }
            }
          }
          ... on WpPage_Sections_Blocks_ImageAside {
            fieldGroupName
            type
            title
            subtitle
            logo {
              sourceUrl
            }
            imagePosition
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            content
            button {
              url
              title
              target
            }
          }
          ... on WpPage_Sections_Blocks_Testimonials {
            fieldGroupName
            testimonials {
              ... on WpTestimonial {
                content
                id
              }
            }
            testimonialsColumn2 {
              ... on WpTestimonial {
                content
                id
              }
            }
          }
          ... on WpPage_Sections_Blocks_ContentCentered {
            fieldGroupName
            title
            subtitle
            backgroundColor
            content
            buttons {
              button {
                title
                url
                target
              }
            }
            background {
              sourceUrl
            }
          }
          ... on WpPage_Sections_Blocks_Loans {
            fieldGroupName
            title
            loans {
              ... on WpLoan {
                title
                content
                slug
              }
            }
          }
          ... on WpPage_Sections_Blocks_ApplyNow {
            fieldGroupName
            title
            officers {
              ... on WpLoanOfficer {
                title
                featuredImage {
                  node {
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
                officers {
                  position
                  nmls
                  hixonLendingNmls
                  licensedIn
                  phone {
                    title
                  }
                  email {
                    title
                  }
                  buttons {
                    link {
                      target
                      title
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
